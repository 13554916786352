<template>
  <div class="poppins">
    <div class="d-sm-none d-flex flex-column poppins " style="height: 100vh">
      <div class="h-100 d-flex flex-column justify-content-center">
        <div class="text-center w-100">
          <img src="@/assets/img/home_logo.png" alt="" style="width: 50vw" />
        </div>
        <img src="@/assets/img/home_judul.png" alt="" style="width: 100vw" class="px-3" />
        <div class="text-center px-4">
          <!-- <button class="btn btn-danger my-3 w-100 rounded-pill" @click="goPage('login')">
            Masuk
          </button> -->
        </div>
        <img src="@/assets/img/home_dpr.png" alt="" style="width: 100vw" class="px-3" />
      </div>
    </div>
    <div class="d-none d-sm-block container-fluid ps-0">
      <img src="@/assets/img/home_bendera_2.png" alt="" class="position-absolute" style="width: 20vw; z-index: -1" />
      <div class="row align-items-center">
        <div class="col-lg-6 col-sm-8">
          <div class="d-flex flex-column w-100 align-items-center align-items-lg-end">
            <div class="d-flex flex-column justify-content-center w-150">
              <img src="@/assets/img/home_logo.png" alt="" style="width: 15vw" class="" />
              <div>
                <img src="@/assets/img/home_judul.png" alt="" style="width: 40vw" class="" />
              </div>
              <!-- <button class="btn btn-danger rounded-pill w-100 my-3" @click="goPage('login')">
                MASUK
              </button> -->
              <div class="w-100 d-flex justify-content-center mt-5">
                <img src="@/assets/img/home_dpr.png" alt="" style="width: 35vw" class="" />
              </div>
            </div>

            <div class="text-center ps-5" style="width: 35vw"></div>
          </div>
        </div>

        <div class="col-lg-6 col-sm-4">
          <div class="login-orang"></div>
          <!-- <div class="text-center mt-4">
          Lupa Password?
          <a href="konfirmasi_otp" class="text-danger fw-bold">klik disini</a>
        </div> -->
        </div>
      </div>
    </div>

    <div class=""></div>
  </div>
</template>

<script>
import { base_url, AlertPopup, goPage } from "@/assets/javascript/function";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      email: "",
      password: "",
      url: "login",
      api: "",
      isi_button: `<div class="fs-5">Masuk</div>`,
    };
  },

  methods: {
    AlertPopup,
    goPage,
    Login() {
      this.isi_button = `<div class="fs-5 d-flex"><div class="spinner-border me-3" role="status">
  <span class="sr-only"></span>
</div>
                   <div>Mohon Tunggu</div></div>`;
      let formData = new FormData();
      formData.append("username", this.email);
      formData.append("password", this.password);
      formData.append("url", this.url);
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.url;
      } else {
        this.api = base_url + this.url;
      }
      // formData.append("url", this.url);
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            AlertPopup("success", "Selamat Datang", "", 1500, false);
            Cookies.set("token", response.data.token_refresh);
            goPage("dashboard");
          } else {
            // this.showAlert(response.data.message);
            AlertPopup("error", "", response.data.message, 1500, false);
            this.isi_button = `<div class="fs-5">Masuk</div>`;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.login-page {
  min-height: 100vh;
  /* background-color: var(--jempolku); */
}

.login-logo {
  min-height: 100vh;
  max-height: 500px;
  background-image: url("@/assets/img/login.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-orang {
  min-height: 100vh;
  max-height: 500px;
  background-image: url("@/assets/img/home_orang.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 25% 15%;
}

.icon-whatsapp-logo {
  font-size: 2rem !important;
}

.input-form-login {
  border-radius: 1rem !important;
  height: 60px;
}

#background-login-form {
  border-radius: 0.7rem !important;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.34);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.34);
}

.background-login-form {
  border-radius: 0.7rem !important;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.34);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.34);
}

/* .btn-primary {
  color: #fff;
  background-color: var(--jempolku2) !important;
  border-color: var(--jempolku2) !important;
} */
.row>* {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.bg-jempolku {
  background-color: var(--jempolku);
}

.py-px-login-card {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

@media screen and (max-width: 1024px) {
  .login-logo {
    background-size: cover;
  }

  .login-orang {
    background-size: cover;
  }
}
</style>
