<template>
  <NavbarDefault />
  <div id="form_pendaftaran" class="poppins">
    <div class="mt-5 pt-5"></div>
    <h4 class="text-center  fw-bold">VERIFIKASI EMAIL</h4>

    <div class="container">
      <form @submit.prevent="insert">
        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          DATA DIRI
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="nik" class="form-label">
                No Induk Kependudukan (NIK)<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nik" class="form-control" id="nis" v-model="currentRecord.no_ktp" minlength="16"
                maxlength="16" />
            </div>
            <div class="col-12 col-md-3">
              <label for="whatsapp" class="form-label">
                No Whatsapp<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="tel" name="whatsapp" class="form-control" id="whatsapp" v-model="currentRecord.whatsapp"
                placeholder="Masukan no whatsapp yang aktif" required />
            </div>

            <div class="col-12 col-md-3">
              <label for="email" class="form-label">
                Email <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 ">
              <input type="text" name="email" class="form-control" id="nis" placeholder="Masukan email yang aktif"
                v-model="currentRecord.email" required />
            </div>
            <div class=""></div>
          </div>
          <div class="row">
            <div class="col-12 mt-1">
              <div class="d-flex justify-content-end align-items-center">
                <div>
                  <button type="submit" class="btn btn-danger" :disabled="isGambarSukses == false">Verifikasi</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>
    <button class="btn" ref="modalShowButton" data-bs-toggle="modal" data-bs-target="#tambah_data" hidden></button>
    <div class="modal fade" id="tambah_data" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="label_data_karyawan">Kode Akses</h5>
            <!-- <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button> -->
          </div>
          <form @submit.prevent="verifikasi()">
            <div class="modal-body">
              <div class="mb-3">
                <label for="kode" class="form-label">
                  Masukan kode yang dikirimkan via whatsapp atau email
                </label>
                <input type="text" class="form-control" v-model="currentRecord.id" placeholder="Kode" />
              </div>
            </div>
            <div class="modal-footer">
              <!-- <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Batal
            </button> -->
              <button type="button" ref="close_modal" data-bs-dismiss="modal" aria-label="Close" hidden>

              </button>
              <button type="submit" class="btn btn-primary">
                Verifikasi
              </button>

            </div>
          </form>

        </div>
      </div>
    </div>
    <div class="modal fade" id="lihat_foto" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered ">

        <div class="mb-3 w-100 text-center">
          <img :src="source_foto" alt="" style="max-width: 100vw; max-height: 100vh;">
        </div>
      </div>
    </div>

  </div>


  <!-- <div class="text-end">
    <span class="me-2">Selanjutnya</span>
    <button type="submit" class="btn btn-success rounded-circle py-2">
      <i class="bx bx-arrow-back bx-rotate-180"></i>
    </button>
  </div> -->
</template>

<script>
// @ is an alias to /src
import Cookies from "js-cookie";
import { goPage, base_url, AlertPopup, ShowLoading, CloseLoading, ErrorConnectionTimeOut, JustYearNow, base_url_wilayah_indonesia, randomString, AlertBottomError } from "@/assets/javascript/function";
import iziToast from "izitoast";
import imageCompression from 'browser-image-compression';
import NavbarDefault from "@/components/NavbarDefault.vue";

import axios from "axios";


export default {
  data() {
    return {
      source_foto: "",
      scroll_field: "",
      isGambarSukses: true,
      isMobile: false,
      position: "",
      kunci_tahun_lulus_belakang: "",
      kunci_tahun_lulus_depan: "",
      page: "ra_calon",
      api: "",
      ID: "",
      name: "testing",
      selectedOption: "Islam",
      selectedOption2: "Pria",
      selectedOptionKabupaten: "",
      selectedOptionKecamatan: "",
      statusOrangTua: "Kandung",
      kecamatanList: [],
      kabupatenList: [],
      kelurahanList: [],
      tahunLulusList: [],

      sumberListrikList: [{
        nama: "Sistem Pembayaran Pascabayar"
      }, {
        nama: "Sistem Pembayaran Prabayar"
      }],
      kepemilikanList: [
        {
          nama: "Milik Sendiri",
        },
        {
          nama: "Kontrak/Sewa",
        },
        {
          nama: "Bebas Sewa(menumpang)",
        },
        {
          nama: "Dinas",
        },
      ],
      statusOrangTuaList: [
        {
          nama: "Kandung",
        },
        {
          nama: "Tiri",
        },
        {
          nama: "Angkat",
        },
        {
          nama: "Lainnya",
        },
      ],
      pendidikanTerakhirList: [
        {
          nama: "Tidak/ Belum Sekolah"
        },
        {
          nama: "SD/Sederajat"
        },
        {
          nama: "SLTP/Sederajat"
        },
        {
          nama: "SLTA/Sederajat"
        },
        {
          nama: "Diploma "
        },
        {
          nama: "S1"
        },
        {
          nama: "S2"
        },
        {
          nama: "S3"
        },
      ],
      provinsiList: [],
      kotaList: [],
      pekerjaanList: [
        {
          nama: "PNS",
        },
        {
          nama: "PEG SWASTA",
        },
        {
          nama: "WIRAUSAHA",
        },
        {
          nama: "TNI/POLRI",
        },
        {
          nama: "PETANI",
        },
        {
          nama: "NELAYAN",
        },
        {
          nama: "LAINNYA",
        },
      ],
      kampusList: [],
      prodi: "", // a string to store the selected option
      currentRecord: {

        nama_lengkap: "",
        jenis_kelamin: "",
        agama: "",
        no_ktp: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        alamat_siswa: "",
        provinsi: "",
        kecamatan: "",
        kabupaten: "",
        kelurahan: "",
        kota: "",
        lingkungan: "",
        kodepos: "",
        telepon_siswa: "",
        whatsapp: "",


        //data keluarga
        nama_ayah: "",
        pekerjaan_ayah: "",
        pendidikan_terakhir_ayah: "",
        nama_ibu: "",
        pekerjaan_ibu: "",
        pendidikan_terakhir_ibu: "",
        jumlah_tanggungan: "",
        telepon_ortu: "",
        whatsapp_ortu: "",
        status_ortu: "",
        penghasilan_ayah: "",
        penghasilan_ibu: "",

        //pendidikan
        nama_sekolah: "",
        npsn_sekolah: "",
        nisn_sekolah: "",
        jurusan_sekolah: "",
        tahun_lulus_sekolah: "",
        prestasi1_sekolah: "",
        prestasi2_sekolah: "",

        //pilihan
        nama_pt: "",
        alamat_pt: "",
        fakultas_pt: "",
        prodi_pt: "",
        nim_pt: "",

        //rumah tinggal keluarga
        alamat_rumah: "",
        kepemilikan_rumah: "Bebas Sewa(menumpang)",
        tahun_perolehan_rumah: "",
        sumber_listrik_rumah: "",
        luas_bangunan_rumah: "",

        //aku media sosial
        facebook: "",
        instagram: "",
        twitter: "",
        tiktok: "",
        youtube: "",

        //tim rekomendasi
        nama_rekomendasi: "",
        pekerjaan_rekomendasi: "",
        jabatan_rekomendasi: "",
        hp_rekomendasi: "",
        alamat_rekomendasi: "",
        foto_bersama: "",
        tanda_tangan: "",
        tanda_tangan_rekomendasi: "",

        foto_kip: "",
        foto_kartu_keluarga: "",
        foto_ktp_ayah: "",
        foto_ktp_ibu: "",
        foto_ktp_siswa: "",
        foto_sktl: "",
        foto_rekening_listrik: "",
        foto_rekening_air: "",
        foto_keluarga: "",
        foto_pas: "",
        foto_ptn: "",

        //bekas
        nama_ijazah: "",
        warga_negara: "",
        nama_ibu_kandung: "",

        //tahun lulus
        tahunLulusList: [],

        id: "",
      },
      signatureData: null,
      signatureData2: null,
    };
  },
  name: "HomeView",
  components: { NavbarDefault },
  methods: {
    setAlamatPt() {
      const index = this.kampusList.findIndex((record) => record.nama == this.currentRecord.nama_pt)
      this.currentRecord.alamat_pt = this.kampusList[index].alamat;
    },
    async compressImage(file) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };

      try {
        const compressedFile = await imageCompression(file, options);
        return this.blobToFile(compressedFile, compressedFile.name);
        // const imageUrl = URL.createObjectURL(compressedFile);
        // console.log(imageUrl);
        // this.imageUrl = imageUrl;
      } catch (error) {
        console.log('compression error:', error);
      }


    },
    ShowModal() {
      this.$refs.modalShowButton.click();

    },
    provinsi_index() {
      ShowLoading();
      axios
        .post(base_url_wilayah_indonesia + "provinsi_index", '')
        .then((response) => {
          if (response.data.status == true) {
            // AlertPopup("success", response.data.message, "", 1500, false);
            this.provinsiList = response.data.data;
          }
          if (response.data.status == false) {
            AlertBottomError("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    kabupaten_index(id, jenis) {
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);

      axios
        .post(base_url_wilayah_indonesia + "kabupaten_index", formData)
        .then((response) => {
          if (response.data.status == true) {
            if (jenis == "ortu") {
              this.kotaList_ortu = response.data.data;
              console.log(this.kotaList_ortu);
            }
            if (jenis == "siswa") {
              this.kotaList = response.data.data;
            }
            if (jenis == "sekolah") {
              this.kotaList_sekolah = response.data.data;
            }
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertBottomError("error", "", response.data.message, 1500, false);
            CloseLoading();
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    kecamatan_index(id, jenis) {
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);
      axios
        .post(base_url_wilayah_indonesia + "kecamatan_index", formData)
        .then((response) => {
          if (response.data.status == true) {

            if (jenis == "ortu") {
              this.kecamatanList_ortu = response.data.data;
            }
            if (jenis == "siswa") {
              this.kecamatanList = response.data.data;
            }
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertBottomError("error", "", response.data.message, 1500, false);
            CloseLoading();
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    blobToFile(blob, fileName) {
      const options = {
        type: blob.type,
        lastModified: blob.lastModified
      };

      const file = new File([blob], fileName, options);
      return file;
    },
    kelurahan_index(id, jenis) {
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);
      axios
        .post(base_url_wilayah_indonesia + "kelurahan_index", formData)
        .then((response) => {
          if (response.data.status == true) {
            if (jenis == "ortu") {
              this.kelurahanList_ortu = response.data.data;
            }
            if (jenis == "siswa") {
              this.kelurahanList = response.data.data;
            }
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertBottomError("error", "", response.data.message, 1500, false);
            CloseLoading();
          }
          CloseLoading();
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });

    },
    checkInputValidity_wilayah(pilihan) {
      const enteredValue = this.currentRecord['' + pilihan];
      let validOptions;

      if (pilihan == 'agama') {
        validOptions = this.agamaList.map(option => option.nama);
      }
      if (pilihan == 'provinsi') {
        validOptions = this.provinsiList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.provinsiList.filter(provinsi => provinsi.nama.includes(this.currentRecord.provinsi));
          this.kabupaten_index(filter[0].id, 'siswa');
        }
      }
      if (pilihan == 'kota') {
        validOptions = this.kotaList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.kotaList.filter(kota => kota.nama.includes(this.currentRecord.kota));
          this.kecamatan_index(filter[0].id, 'siswa');
        }
      }
      if (pilihan == 'kecamatan') {
        validOptions = this.kecamatanList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.kecamatanList.filter(provinsi => provinsi.nama.includes(this.currentRecord.kecamatan));
          this.kelurahan_index(filter[0].id, 'siswa');
        }
      }
      if (pilihan == 'kelurahan') {
        validOptions = this.kelurahanList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        }
      }

      if (pilihan == 'provinsi_ortu') {
        validOptions = this.provinsiList_ortu.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.provinsiList_ortu.filter(provinsi => provinsi.nama.includes(this.currentRecord.provinsi_ortu));
          this.kabupaten_index(filter[0].id, 'ortu');
        }
      }
      if (pilihan == 'kota_ortu') {
        validOptions = this.kotaList_ortu.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.kotaList_ortu.filter(kota => kota.nama.includes(this.currentRecord.kota_ortu));
          this.kecamatan_index(filter[0].id, 'ortu');
        }
      }
      if (pilihan == 'kecamatan_ortu') {
        validOptions = this.kecamatanList_ortu.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.kecamatanList_ortu.filter(provinsi => provinsi.nama.includes(this.currentRecord.kecamatan_ortu));
          this.kelurahan_index(filter[0].id, 'ortu');
        }
      }
      if (pilihan == 'kelurahan_ortu') {
        validOptions = this.kelurahanList_ortu.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';

        }
      }

      if (pilihan == 'provinsi_sekolah') {
        validOptions = this.provinsiList_sekolah.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        } else {
          let filter = this.provinsiList_sekolah.filter(provinsi => provinsi.nama.includes(this.currentRecord.provinsi_sekolah));
          this.kabupaten_index(filter[0].id, 'sekolah');
        }
      }
      if (pilihan == 'kota_sekolah') {
        validOptions = this.kotaList_sekolah.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        }
      }


      if (pilihan == 'pendidikan_terakhir_ayah' || pilihan == 'pendidikan_terakhir_ibu') {
        validOptions = this.pendidikanTerakhirList.map(option => option.nama);
      }

      if (pilihan == 'ukuran_jas_akademis' || pilihan == 'ukuran_jas_akademis') {
        validOptions = this.ukuranJasList.map(option => option.nama);
      }


    },
    HideModal() {
      this.$refs.close_modal.click();

    },
    saveData() {
      this.currentRecord.agama = this.selectedOption;
      this.currentRecord.jenis_kelamin = this.selectedOption2;
      this.currentRecord.prodi = this.prodi;
      localStorage.setItem("data_diri", JSON.stringify(this.currentRecord));
      goPage("data_akun");
    },
    indexData() {
      //note nanti diganti
      this.currentRecord.nama_ijazah = this.currentRecord.nama_lengkap;
      this.currentRecord.warga_negara = "WNI";
      if (
        Cookies.get("nama_jurusan") != "" ||
        Cookies.get("nama_jurusan") != null
      ) {
        this.prodi = Cookies.get("nama_jurusan");
      }

      if (localStorage.getItem("data_diri") !== null) {
        let data = JSON.parse(localStorage.getItem("data_diri"));
        this.currentRecord = Object.assign({}, data);


        if (this.currentRecord.agama != '') {
          this.selectedOption = 'Islam';
        }
        if (this.currentRecord.jenis_kelamin != '') {
          this.selectedOption2 = 'Pria';
        }

        this.currentRecord.prodi = this.prodi;
        CloseLoading();
      }

    },
    insert() {
      ShowLoading();
      const formData = new FormData();
      let no_hp = this.currentRecord.whatsapp;
      this.currentRecord.whatsapp = "62" + no_hp.slice(1);

      Object.entries(this.currentRecord).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append('nik', this.currentRecord.no_ktp);
      this.api = base_url + 'ra_calon_account';

      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            AlertPopup("success", "", response.data.message, 1500, false);
            // console.log(response.data);
          }
          if (response.data.status == false) {
            this.currentRecord.whatsapp = no_hp.slice(1);
            this.currentRecord.whatsapp = "0" + no_hp.slice(1);

            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error)
        });


    },
    // verifikasi(id) {
    //   ShowLoading();
    //   const formData = new FormData();

    //   formData.append("url", "ra_calon_account");
    //   formData.append("id", id);

    //   if (process.env.NODE_ENV === "development") {
    //     this.api = base_url + "ra_calon_account";
    //   } else {
    //     this.api = base_url + "ra_calon_account";
    //   }


    //   // Add a new record to the API
    //   axios
    //     .post(this.api, formData)
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         this.currentRecord = response.data.data;
    //         CloseLoading();

    //       }
    //       if (response.data.status == false) {
    //         AlertPopup("error", "", response.data.message, 1500, false);
    //         goPage('/');
    //       }

    //     })
    //     .catch((error) => {
    //       ErrorConnectionTimeOut(error);

    //     });
    // },
    updateSelectedOption(option) {
      this.selectedOption = option;
    },
    updateSelectedOption2(option) {
      this.selectedOption2 = option;
    },
    updateSelectedOptionKecamatan(option) {
      this.selectedOptionKecamatan = option;
    },
    updateSelectedOptionKabupaten(option) {
      this.selectedOptionKabupaten = option;
    },

    saveSignature() {
      const dataURL = this.$refs.signaturePad.toDataURL();
      return dataURL;
    },
    saveSignature2() {
      const dataURL = this.$refs.signaturePad2.toDataURL();
      return dataURL;
    },
    clearSignature2() {
      this.$refs.signaturePad2.clear();
    },
    clearSignature() {
      this.$refs.signaturePad.clear();
    },
    checkInputValidity(pilihan) {
      const enteredValue = this.currentRecord['' + pilihan];
      let validOptions;
      if (pilihan == 'pendidikan_terakhir_ayah' || pilihan == 'pendidikan_terakhir_ibu') {
        validOptions = this.pendidikanTerakhirList.map(option => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord['' + pilihan] = '';
        }
      }
    },

    delay() {

      return new Promise(resolve => {
        setTimeout(() => {

          resolve();
        }, 5000); // Sleep for 1 second
      });
    },

    async updateFile(nama_file) {
      this.isGambarSukses = false;
      if (this.$refs[nama_file].files[0] != undefined) {

        // ShowLoading();
        iziToast.info({
          timeout: 0,
          title: "Sedang Upload Dokumen",
          position: this.position,
        });


        var toast = document.querySelector('.iziToast');
        this.currentRecord[nama_file] = '';

        this.currentRecord[nama_file] = 'https://s7-api.arthaskyline.com/loading.gif';
        const formData = new FormData();

        // Object.entries(this.newRecord).forEach(([key, value]) => {
        //   formData.append(key, value);
        // });
        var filecompress = await this.compressImage(this.$refs[nama_file].files[0]);
        // console.log(Math.round(filecompress.size / 1024));
        formData.append("id", this.currentRecord.kode_unik);
        formData.append("url", "ra_calon_update_file");
        formData.append(nama_file, filecompress);

        if (process.env.NODE_ENV === "development") {
          this.api = base_url + "ra_calon_update_file";
        } else {
          this.api = base_url + "ra_calon_update_file";
        }
        // Add a new record to the API
        axios
          .post(this.api, formData, this.config)
          .then((response) => {
            if (response.data.status == true) {
              // this.currentRecord[nama_file] = "https://s7-api.arthaskyline.com/confetti.gif";


              iziToast.hide({}, toast);
              let nama_file2 = nama_file.replace(/_/g, ' ');
              iziToast.success({
                timeout: 3000,
                title: nama_file2 + " berhasil di update",
                position: this.position,
              });

              // CloseLoading();
              this.isGambarSukses = true;

              //add delay on HERE CHAT GPT
              // setTimeout(() => {
              //   this.currentRecord[nama_file] = response.data.file_url;
              // }, 3000);

              this.currentRecord[nama_file] = response.data.file_url + "?v=" + randomString();
            }
            if (response.data.status == false) {
              // AlertPopup("error", "", response.data.message, 1500, false);
              this.isGambarSukses = false;
              iziToast.hide({}, toast);
              iziToast.error({
                timeout: 3000,
                title: response.data.message,
                position: this.position,
              });

            }
          })
          .catch((error) => {
            ErrorConnectionTimeOut(error);
            console.log(error);
          });
      }
    },
    scrollToElement(nama) {
      const element = this.$refs[nama];
      const paddingTop = 270; // Specify the top padding value in pixels

      if (element) {
        this.$scrollTo.scrollTo(element, 500, { offset: -paddingTop });
      } else {
        console.error(`Element with ref ${nama} not found.`);
      }
    }

  },

  mounted() {


    this.kunci_tahun_lulus_belakang = JustYearNow() - 2;
    this.kunci_tahun_lulus_depan = JustYearNow();

    // Generate the year list
    for (let list = this.kunci_tahun_lulus_belakang; list <= this.kunci_tahun_lulus_depan; list++) {
      this.tahunLulusList.push({ nama: list });
    }
    const userAgent = navigator.userAgent.toLowerCase();
    this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

    if (this.isMobile == false) {
      this.position = "bottomRight";
    } else {
      this.position = "topLeft";
    }
  },

};
</script>

<style></style>

