<template >
    <div id="pdf-content" class="px-5 pdf-content container " ref="pdf_content">
        <img src="@/assets/img/login_logo.png" alt="" class="position-absolute start-0" width="130">
        <!-- dicek nanti ki ini yang 3 (search di seluruhu projek))-->
        <!-- <img src="@/assets/img/logo_login.png" alt="" class="position-absolute end-0" width="170"> -->
        <div class="fs-4 text-center fw-bold">RUMAH ASPIRASI</div>
        <div class="fs-4 text-center mb-3 fw-bold">dr. Sofyan Tan</div>
        <div class="fs-4 text-start mb-2 fw-bold">A. Biodata Siswa</div>
        <div class="row">

            <div class="col-3 ">
                <img :src="recordsPDF.foto_pas" width="180">
            </div>
            <div class="col-1">

            </div>
            <div class="col-8">
                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <td style="width: 29%" class="p-0 fw-bold">1. Identitas Diri</td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">Nama Lengkap</td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.nama_lengkap }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Jenis Kelamin
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.jenis_kelamin }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Agama
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.agama }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                No Ktp
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.no_ktp }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Tempat Lahir
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.tempat_lahir }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Tanggal Lahir
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.tanggal_lahir }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Alamat Lengkap
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.alamat_siswa }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Kecamatan
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.kecamatan }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Kabupaten
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.kabupaten }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Kode Pos
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.kodepost }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Telepon Siswa
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.telepon_siswa }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Whatsapp
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.whatsapp }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Email
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.email }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <table>
                    <tbody class="table table-borderless ">
                        <tr>
                            <td class="fw-bold">2. Data Keluarga</td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Nama Ayah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.nama_ayah }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Pekerjaan Ayah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.pekerjaan_ayah }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Nama Ibu
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.pekerjaan_ibu }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                jumlah_tanggungan
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.jumlah_tanggungan }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Telepon Ortu
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.telepon_ortu }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Whatsapp Ortu
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.whatsapp_ortu }}
                            </td>
                        </tr>

                        <tr>
                            <td style="width: 29%" class="p-0">
                                Penghasilan Ayah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.penghasilan_ayah }}
                            </td>
                        </tr>

                        <tr>
                            <td style="width: 29%" class="p-0">
                                Penghasilan Ibu
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.penghasilan_ibu }}
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                        </tr>
                        <tr>
                            <td class="fw-bold pt-3">
                                3. Data Sekolah
                            </td>
                        </tr>

                        <tr>
                            <td style="width: 29%" class="p-0">
                                Nama Sekolah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.nama_sekolah }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                NPSN Sekolah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.npsn_sekolah }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                NISN Sekolah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.nisn_sekolah }}
                            </td>
                        </tr>

                        <tr>
                            <td style="width: 29%" class="p-0">
                                Jurusan Sekolah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.jurusan_sekolah }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Tahun Lulus Sekolah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.tahun_lulus_sekolah }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Prestasi 1 Sekolah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.prestasi1_sekolah }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Prestasi 2 Sekolah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.prestasi2_sekolah }}
                            </td>
                        </tr>
                        <div class=" html2pdf__page-break"></div>
                        <tr>
                            <td class="fw-bold">4. Data Pilihan</td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Nama Universitas
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.nama_pt }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Alamat Universitas
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.alamat_pt }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Fakultas
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.fakultas_pt }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Prodi
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.prodi_pt }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                NIM
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.nim_pt }}
                            </td>
                        </tr>
                        <tr>
                            <td class="fw-bold pt-3">5. Rumah Tinggal keluarga</td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Alamat Rumah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.alamat_rumah }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Kepemilikan Rumah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.kepemilikan_rumah }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Tahun Perolehan Rumah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.tahun_perolehan_rumah }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Sumber Listrik Rumah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.sumber_listrik_rumah }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Luas Bangunan Rumah
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.luas_bangunan_rumah }}
                            </td>
                        </tr>
                        <tr>
                            <td class="pt-5"></td>
                        </tr>
                        <tr>
                            <td class="fw-bold pt-5">6.Sosial Media</td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Facebook
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.facebook }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Instagram
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.instagram }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Twitter
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.twitter }}
                            </td>
                        </tr>
                        <tr>
                            <td class="fw-bold pt-3">7.Tim yang merekomendasi </td>
                        </tr>
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Nama Rekomendasi
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                {{ recordsPDF.nama_rekomendasi }}
                            </td>
                        </tr>
                        <!-- <tr>
                <td style="width: 29%" class="p-0">
                  No Handphone Rekomendasi
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.whatsapp_rekomendasi }}
                </td>
              </tr> -->
                        <tr>
                            <td style="width: 29%" class="p-0">
                                Tanda Tangan Tim Rekomendasi
                            </td>
                            <td style="width: 1%" class="p-0">:</td>
                            <td style="width: 70%" class="p-0 fw-bold">
                                <img class="border" :src="recordsPDF.tanda_tangan_rekomendasi" alt=""
                                    style="max-height: 125px;">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p>
                Dengan ini saya menyatakan dengan penuh kesadaran bahwa data yang saya isi dalam formulir ini adalah benar.
                Saya bersedia diberikan sanksi apabila melanggar ketentuan dan memberikan informasi yang tidak benar
            </p>
            <div class="row ">
                <div class="col-6"></div>
                <div class="col-6 ">
                    <div class="">
                        <div class="w-100 ">
                            <div class="row">
                                <div class="col-8">
                                    Medan
                                </div>
                                <div class="col-4 text-end">
                                    31 Maret 2023
                                </div>
                            </div>
                        </div>
                        <div class="text-center border">
                            <img class="" :src="recordsPDF.tanda_tangan" alt="" width="300">
                        </div>
                        <div class="border-bottom border-2 border-dark ">
                            <div class="text-center">
                                {{ recordsPDF.nama_lengkap }}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div style="height: 200px;">

            </div>

            <div class="fw-bold ">8. Kelengkapan Data </div>
            <div class="d-flex justify-content-around">
                <div class="">
                    <img :src="recordsPDF.foto_kip" style="max-height: 220px;  min-height: 220px;">
                    <div class="text-center">
                        <p>Foto KIP</p>
                    </div>
                </div>
                <div class="">
                    <img :src="recordsPDF.foto_kartu_keluarga" style="max-height: 220px;  min-height: 220px;">
                    <div class="text-center">
                        <p>Foto Kartu Keluarga</p>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-around">
                <div class="">
                    <img :src="recordsPDF.foto_ktp_ayah_ibu" style="max-height: 220px; min-height: 220px;">
                    <div class="text-center">
                        <p>Foto KTP Orang Tua</p>
                    </div>
                </div>
                <div class="">
                    <img :src="recordsPDF.foto_ktp_siswa" style="max-height: 220px;  min-height: 220px;">
                    <div class="text-center">
                        <p>Foto Resi KTP</p>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-around">
                <div class="">
                    <img :src="recordsPDF.foto_sktl" style="max-height: 220px;  min-height: 220px;">
                    <div class="text-center">
                        <p>Foto SKTL</p>
                    </div>
                </div>
                <div class="">
                    <img :src="recordsPDF.foto_rekening_listrik" style="max-height: 220px;  min-height: 220px;">
                    <div class="text-center">
                        <p>Foto Rekening Listrik</p>
                    </div>
                </div>
            </div>
            <div class="html2pdf__page-break"></div>
            <div class="d-flex justify-content-around">
                <div class="">
                    <img :src="recordsPDF.foto_rekening_air" style="max-height: 220px;  min-height: 220px;">
                    <div class="text-center">
                        <p>Foto Rekening Air</p>
                    </div>
                </div>
                <div class="">
                    <img :src="recordsPDF.foto_keluarga" style="max-height: 220px;  min-height: 220px;">
                    <div class="text-center">
                        <p>Foto Kartu Keluarga</p>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-around">
                <div class="">
                    <img :src="recordsPDF.foto_ptn" style="max-height: 220px;  min-height: 220px;">
                    <div class="text-center">
                        <p>Foto Bukti Lulus PTN & Bukti UKT</p>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-around">
                <div class="">
                    <img :src="recordsPDF.foto_rumah_depan" style="max-height: 220px;  min-height: 220px;">
                    <div class="text-center">
                        <p>Foto Rekening Air</p>
                    </div>
                </div>
                <div class="">
                    <img :src="recordsPDF.foto_rumah_dalam" style="max-height: 220px;  min-height: 220px;">
                    <div class="text-center">
                        <p>Foto Kartu Keluarga</p>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-around">
                <div class="">
                    <img :src="recordsPDF.foto_rumah_dapur" style="max-height: 220px;  min-height: 220px;">
                    <div class="text-center">
                        <p>Foto Rekening Air</p>
                    </div>
                </div>
                <div class="">
                    <img :src="recordsPDF.foto_bersama" style="max-height: 220px;  min-height: 220px;">
                    <div class="text-center">
                        <p>Foto Kartu Keluarga</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {
    base_url_header,
    AlertPopup,
    ShowLoading,
    CloseLoading,
    ErrorConnectionTimeOut,
    // base_url_header, ErrorConnectionTimeOut
} from "@/assets/javascript/function";

import Cookies from "js-cookie";
import axios from "axios";
export default {
    data() {
        return {
            url2: "ra_rekomendasi_details",
            id_mahasiswa: "",
            recordsPDF: {
                nama_lengkap: "",
                jenis_kelamin: "",
                agama: "",
                no_ktp: "",
                tempat_lahir: "",
                tanggal_lahir: "",
                kecamatan: "",
                kabupaten: "",
                kodepost: "",
                telepon_siswa: "",
                alamat_siswa: "",
                nama_ayah: "",
                pekerjaan_ayah: "",
                nama_ibu: "",
                pekerjaan_ibu: "",
                jumlah_tanggungan: "",
                telepon_ortu: "",
                whatsapp_ortu: "",
                penghasilan_ayah: "",
                penghasilan_ibu: "",
                nama_sekolah: "",
                npsn_sekolah: "",
                nisn_sekolah: "",
                jurusan_sekolah: "",
                tahun_lulus_sekolah: "",
                prestasi1_sekolah: "",
                prestasi2_sekolah: "",
                nama_pt: "",
                alamat_pt: "",
                fakultas_pt: "",
                prodi_pt: "",
                nim_pt: "",
                alamat_rumah: "",
                kepemilikan_rumah: "",
                tahun_perolehan_rumah: "",
                sumber_listrik_rumah: "",
                luas_bangunan_rumah: "",
                facebook: "",
                instagram: "",
                twitter: "",
                foto_kip: "",
                foto_kartu_keluarga: "",
                foto_ktp_ayah_ibu: "",
                foto_ktp_siswa: "",
                foto_sktl: "",
                foto_rekening_listrik: "",
                foto_rekening_air: "",
                foto_keluarga: "",
                foto_ptn: "",
                foto_rumah_depan: "",
                foto_rumah_dalam: "",
                foto_rumah_dapur: "",
                foto_bersama: "",
                foto_pas: "",
                tanda_tangan_rekomendasi: "",
                tanda_tangan: "",
                whatsapp: "",
                email: "",
                progress: "",
                status: "",
                created_at: "",
                updated_at: ""
            },
        };
    },

    methods: {
        generatePDF(id) {
            ShowLoading();
            let formData = new FormData();
            formData.append("url", this.url2);
            formData.append("id", id);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url_header + this.url2;
            } else {
                this.api = base_url_header;
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        this.recordsPDF = response.data.data[0];

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        generatePDF2() {
            // Hide any elements that you don't want to include in the PDF
            document.querySelectorAll('.no-print').forEach(el => el.style.display = 'none');

            // Wait for all images to load before printing
            const images = document.querySelectorAll('img');
            const numImages = images.length;
            let numLoaded = 0;

            images.forEach(img => {
                if (img.complete) {
                    numLoaded++;
                } else {
                    img.addEventListener('load', () => {
                        numLoaded++;
                        if (numLoaded === numImages) {
                            this.print();
                        }
                    });
                    img.addEventListener('error', () => {
                        numLoaded++;
                        if (numLoaded === numImages) {
                            this.print();
                        }
                    });
                }
            });

            if (numLoaded === numImages) {
                this.print();
            }
        },

        print() {
            // Set the page size to A4
            const style = document.createElement('style');
            style.textContent = `@media print { @page { size: A4; } }`;
            document.head.appendChild(style);
            // Wait for any dynamic content to load before printing
            setTimeout(() => {
                window.print();

                // Show any hidden elements after printing
                document.querySelectorAll('.no-print').forEach(el => el.style.display = 'block');

                // Remove the A4 page size style
                document.head.removeChild(style);
                window.close();
            }, 1000);

        }


    },
    mounted() {
        this.token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 5000,
        }
        this.id_mahasiswa = localStorage.getItem('id_mahasiswa');
        this.generatePDF(this.id_mahasiswa);
        this.generatePDF2();

    },
};
</script>