<template>
  <NavbarDefault />
  <div class="container pt-5 page poppins">
    <div class="sticky-top pt-4 pb-3" style="background-color: white">
      <div class="col-12 pt-4">
        <div class="d-flex align-items-end justify-content-between mb-3">
          <div class="fw-bold fs-4">Data Verifikasi</div>

          <button class="btn btn-outline-danger" @click="logout()">
            <i class="ri-logout-box-line me-2"></i> Keluar
          </button>
        </div>
      </div>

      <!-- tambah data admin -->
      <div class="mb-2 d-flex">
        <div class="col-5 col-lg-5 pe-1">
          <input class="form-control" id="myInput" type="text" placeholder="Cari " />
        </div>
        <div class="col-lg-2"></div>
        <!-- <div class="col-4 pe-1">
            <button
              class="btn btn-success me-1"
              onclick="export_data('kategori_index', 'data_kategori')"
            >
              <div class="d-flex align-items-center">
                <i class="bx bxs-file-export me-1"></i>Export Excel
              </div>
            </button>
            <button
              class="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#import_data"
            >
              <i class="bx bxs-file-import"></i> Import Prodi
            </button>
          </div> -->
        <div class="col-7 col-lg-5">
          <div class="d-flex justify-content-end">
            <button class="btn btn-danger d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#tambah_data">
              <i class="ri-user-add-fill me-2"></i>
              Tambah Mahasiswa
            </button>
          </div>
        </div>
      </div>
    </div>

    <table class="table table-striped" id="tabel_data">
      <thead
        style="
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              position: sticky !important;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              top: 136px !important;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              background-color: white;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            ">
        <tr>
          <th scope="col" style="width: 5%">No</th>

          <th scope="col">Mahasiswa/i</th>
          <th scope="col" style="width: 30%">Aksi</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(record, index) in records" :key="record.id">
          <td>{{ index + 1 }}</td>
          <td>
            Nama : <span class="fw-bold">{{ record.nama_lengkap }}</span> <br />
            Whatsapp : <span class="fw-bold">{{ record.whatsapp }}</span> <br />
            Email : <span class="fw-bold">{{ record.email }}</span>
          </td>
          <td>
            <div class="d-md-none d-block w-100">
              <button v-if="record.progress === 'PENDING'"
                class="btn btn-danger update_button d-flex align-items-center w-100 mb-1" @click="delete_data(record.id)"
                data-bs-toggle="modal" data-bs-target="#update_data">
                <i class="ri-delete-bin-6-fill me-2"></i> Hapus
              </button>
              <button v-if="record.progress === 'PENDING'"
                class="btn btn-primary update_button d-flex align-items-center w-100 mb-1"
                @click="generatePDF(record.id)">
                <i class="ri-eye-fill me-2"></i> Lihat
              </button>
              <button v-if="record.progress === 'PENDING'"
                class="btn btn-warning update_button d-flex align-items-center w-100 mb-1" @click="prosesData(record.id)">
                <i class="ri-arrow-right-fill me-2"></i> Verifikasi
              </button>

              <!-- <div class="w-100 d-flex align-items-center justify-content-center"
                v-if="record.progress === 'VERIFY' && record.progress === 'WAWANCARA' && record.progress === 'FINAL'">
                <div class="bg-secondary mx-1 px-2 py-1  rounded-pill">
                  Sedang Seleksi
                </div>
              </div> -->
              <!-- <div class="w-100 d-flex align-items-center justify-content-center text-light "
                v-if="record.progress === 'VERIFY' || record.progress === 'WAWANCARA' || record.progress === 'FINAL' || record.progress === 'DAFTAR_ULANG'">
                <div class="bg-secondary mx-1  rounded-pill px-2 py-1">
                  Sedang Seleksi
                </div>
              </div> -->
              <!-- <div class="w-100 d-flex align-items-center justify-content-center text-light "
                v-if="record.progress === 'DAFTAR ULANG'">
                <div class="bg-success mx-1  rounded-pill px-2 ">
                  Lolos
                </div>
              </div> -->
            </div>
            <div class="d-md-flex d-none">
              <button v-if="record.progress === 'PENDING'"
                class="btn btn-danger mx-1 update_button d-flex align-items-center" @click="delete_data(record.id)"
                data-bs-toggle="modal" data-bs-target="#update_data">
                <i class="ri-delete-bin-6-fill me-2"></i> Hapus
              </button>
              <button v-if="record.progress === 'PENDING'"
                class="btn btn-primary mx-1 update_button d-flex align-items-center" @click="generatePDF(record.id)">
                <i class="ri-eye-fill me-2"></i> Lihat Data
              </button>
              <button v-if="record.progress === 'PENDING'"
                class="btn btn-warning mx-1 update_button d-flex align-items-center" @click="prosesData(record.id)">
                <i class="ri-arrow-right-fill me-2"></i> Verifikasi
              </button>

              <!-- <div class="w-100 d-flex align-items-center justify-content-center text-light "
                v-if="record.progress === 'VERIFY' || record.progress === 'WAWANCARA' || record.progress === 'FINAL' || record.progress === 'DAFTAR_ULANG'">
                <div class="bg-secondary mx-1  rounded-pill px-2 ">
                  Sedang Seleksi
                </div>
              </div>
              <div class="w-100 d-flex align-items-center justify-content-center text-light "
                v-if="record.progress === 'DAFTAR ULANG'">
                <div class="bg-success mx-1  rounded-pill px-2 ">
                  Lolos
                </div>
              </div> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="modal fade" id="tambah_data" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="label_data_karyawan">
              Tambah Verifikasi
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <form @submit.prevent="insert">
            <div class="modal-body">
              <div class="mb-3">
                <label for="kode" class="form-label"> Nama Mahasiswa/i </label>
                <input type="text" class="form-control" v-model="newRecord.nama_lengkap" />
              </div>
              <div class="mb-3">
                <label for="nama" class="form-label"> Email</label>
                <input type="text" class="form-control" v-model="newRecord.email" />
              </div>
              <div class="mb-3">
                <label for="warna" class="form-label"> No Whatsapp</label>
                <input type="text" name="warna" class="form-control" v-model="newRecord.whatsapp" />
              </div>
              <div class="mb-3">
                <label for="no_ktp" class="form-label"> No KTP</label>
                <input type="text" name="no_ktp" class="form-control" v-model="newRecord.no_ktp" />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Batal
              </button>
              <button type="submit" class="btn btn-danger">Simpan</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="" hidden>
    <div id="pdf-content" class="px-5 pdf-content">
      <img src="@/assets/img/login_logo.png" alt="" class="position-absolute start-0" width="130">
      <img src="@/assets/img/home_judul.png" alt="" class="position-absolute end-0" width="170">
      <div class="fs-4 text-center fw-bold">RUMAH ASPIRASI</div>
      <div class="fs-4 text-center mb-3 fw-bold">dr. Sofyan Tan</div>
      <div class="fs-4 text-start mb-2 fw-bold">A. Biodata Siswa</div>
      <div class="row">

        <div class="col-3 ">
          <img :src="recordsPDF.foto_pas" width="180">
        </div>
        <div class="col-1">

        </div>
        <div class="col-8">
          <table class="table table-borderless">
            <tbody>
              <tr>
                <td style="width: 29%" class="p-0 fw-bold">1. Identitas Diri</td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">Nama Lengkap</td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.nama_lengkap }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Jenis Kelamin
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.jenis_kelamin }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Agama
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.agama }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  No Ktp
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.no_ktp }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Tempat Lahir
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.tempat_lahir }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Tanggal Lahir
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.tanggal_lahir }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Alamat Lengkap
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.alamat_siswa }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Kecamatan
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.kecamatan }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Kabupaten
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.kabupaten }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Kode Pos
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.kodepost }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Telepon Siswa
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.telepon_siswa }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Whatsapp
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.whatsapp }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Email
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.email }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12">
          <table>
            <tbody class="table table-borderless ">
              <tr>
                <td class="fw-bold">2. Data Keluarga</td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Nama Ayah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.nama_ayah }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Pekerjaan Ayah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.pekerjaan_ayah }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Nama Ibu
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.pekerjaan_ibu }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  jumlah_tanggungan
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.jumlah_tanggungan }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Telepon Ortu
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.telepon_ortu }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Whatsapp Ortu
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.whatsapp_ortu }}
                </td>
              </tr>

              <tr>
                <td style="width: 29%" class="p-0">
                  Penghasilan Ayah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.penghasilan_ayah }}
                </td>
              </tr>

              <tr>
                <td style="width: 29%" class="p-0">
                  Penghasilan Ibu
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.penghasilan_ibu }}
                </td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td></td>
              </tr>
              <tr>
                <td class="fw-bold pt-3">
                  3. Data Sekolah
                </td>
              </tr>

              <tr>
                <td style="width: 29%" class="p-0">
                  Nama Sekolah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.nama_sekolah }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  NPSN Sekolah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.npsn_sekolah }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  NISN Sekolah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.nisn_sekolah }}
                </td>
              </tr>

              <tr>
                <td style="width: 29%" class="p-0">
                  Jurusan Sekolah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.jurusan_sekolah }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Tahun Lulus Sekolah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.tahun_lulus_sekolah }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Prestasi 1 Sekolah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.prestasi1_sekolah }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Prestasi 2 Sekolah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.prestasi2_sekolah }}
                </td>
              </tr>
              <div class=" html2pdf__page-break"></div>
              <tr>
                <td class="fw-bold">4. Data Pilihan</td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Nama Universitas
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.nama_pt }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Alamat Universitas
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.alamat_pt }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Fakultas
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.fakultas_pt }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Prodi
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.prodi_pt }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  NIM
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.nim_pt }}
                </td>
              </tr>
              <tr>
                <td class="fw-bold pt-3">5. Rumah Tinggal keluarga</td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Alamat Rumah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.alamat_rumah }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Kepemilikan Rumah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.kepemilikan_rumah }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Tahun Perolehan Rumah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.tahun_perolehan_rumah }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Sumber Listrik Rumah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.sumber_listrik_rumah }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Luas Bangunan Rumah
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.luas_bangunan_rumah }}
                </td>
              </tr>
              <tr>
                <td class="fw-bold pt-3">6.Sosial Media</td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Facebook
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.facebook }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Instagram
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.instagram }}
                </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Twitter
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.twitter }}
                </td>
              </tr>
              <tr>
                <td class="fw-bold pt-3">7.Tim yang merekomendasi </td>
              </tr>
              <tr>
                <td style="width: 29%" class="p-0">
                  Nama Rekomendasi
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.nama_rekomendasi }}
                </td>
              </tr>
              <!-- <tr>
                <td style="width: 29%" class="p-0">
                  No Handphone Rekomendasi
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  {{ recordsPDF.whatsapp_rekomendasi }}
                </td>
              </tr> -->
              <tr>
                <td style="width: 29%" class="p-0">
                  Tanda Tangan Tim Rekomendasi
                </td>
                <td style="width: 1%" class="p-0">:</td>
                <td style="width: 70%" class="p-0 fw-bold">
                  <img class="border" :src="recordsPDF.tanda_tangan_rekomendasi" alt="" style="max-height: 125px;">
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p>
          Dengan ini saya menyatakan dengan penuh kesadaran bahwa data yang saya isi dalam formulir ini adalah benar.
          Saya bersedia diberikan sanksi apabila melanggar ketentuan dan memberikan informasi yang tidak benar
        </p>
        <div class="row ">
          <div class="col-6"></div>
          <div class="col-6 ">
            <div class="">
              <div class="w-100 ">
                <div class="row">
                  <div class="col-8">
                    Medan
                  </div>
                  <div class="col-4 text-end">
                    31 Maret 2023
                  </div>
                </div>
              </div>
              <div class="text-center border">
                <img class="" :src="recordsPDF.tanda_tangan" alt="" width="300">
              </div>
              <div class="border-bottom border-2 border-dark ">
                <div class="text-center">
                  {{ recordsPDF.nama_lengkap }}
                </div>
              </div>

            </div>
          </div>
        </div>
        <div style="height: 200px;">

        </div>

        <div class="fw-bold ">8. Kelengkapan Data </div>
        <div class="d-flex justify-content-around">
          <div class="">
            <img :src="recordsPDF.foto_kip" style="max-height: 220px;  min-height: 220px;">
            <div class="text-center">
              <p>Foto KIP</p>
            </div>
          </div>
          <div class="">
            <img :src="recordsPDF.foto_kartu_keluarga" style="max-height: 220px;  min-height: 220px;">
            <div class="text-center">
              <p>Foto Kartu Keluarga</p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-around">
          <div class="">
            <img :src="recordsPDF.foto_ktp_ayah_ibu" style="max-height: 220px; min-height: 220px;">
            <div class="text-center">
              <p>Foto KTP Orang Tua</p>
            </div>
          </div>
          <div class="">
            <img :src="recordsPDF.foto_ktp_siswa" style="max-height: 220px;  min-height: 220px;">
            <div class="text-center">
              <p>Foto Resi KTP</p>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-around">
          <div class="">
            <img :src="recordsPDF.foto_sktl" style="max-height: 220px;  min-height: 220px;">
            <div class="text-center">
              <p>Foto SKTL</p>
            </div>
          </div>
          <div class="">
            <img :src="recordsPDF.foto_rekening_listrik" style="max-height: 220px;  min-height: 220px;">
            <div class="text-center">
              <p>Foto Rekening Listrik</p>
            </div>
          </div>
        </div>
        <div class="html2pdf__page-break"></div>
        <div class="d-flex justify-content-around">
          <div class="">
            <img :src="recordsPDF.foto_rekening_air" style="max-height: 220px;  min-height: 220px;">
            <div class="text-center">
              <p>Foto Rekening Air</p>
            </div>
          </div>
          <div class="">
            <img :src="recordsPDF.foto_keluarga" style="max-height: 220px;  min-height: 220px;">
            <div class="text-center">
              <p>Foto Kartu Keluarga</p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-around">
          <div class="">
            <img :src="recordsPDF.foto_ptn" style="max-height: 220px;  min-height: 220px;">
            <div class="text-center">
              <p>Foto Bukti Lulus PTN & Bukti UKT</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style >
thead::after {
  content: "" !important;
  position: absolute !important;
  bottom: 0;
  left: 0 !important;
  width: 100% !important;
  border-bottom: 1px solid black;
  background-color: white;
}

/* .profile {
  min-height: 236px;
  max-height: 177px;
  background-image: url("@/assets/img/Profile.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
} */

.pdf-content {
  font-family: 'Times New Roman', Times, serif;
}

table {
  page-break-inside: auto
}

tr {
  page-break-inside: avoid;
  page-break-after: auto
}
</style>

<script>
import {
  base_url_header,
  AlertPopup,
  AlertBottom,
  ShowLoading,
  CloseLoading,
  ErrorConnectionTimeOut,
  goPage, goPageNewTab
} from "@/assets/javascript/function";

import NavbarDefault from "@/components/NavbarDefault.vue";
import Cookies from "js-cookie";
import axios from "axios";


export default {
  data() {
    return {
      imageUrl: "",
      records: [],
      recordsPDF: {
        nama_lengkap: "",
        jenis_kelamin: "",
        agama: "",
        no_ktp: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        kecamatan: "",
        kabupaten: "",
        kodepost: "",
        telepon_siswa: "",
        alamat_siswa: "",
        nama_ayah: "",
        pekerjaan_ayah: "",
        nama_ibu: "",
        pekerjaan_ibu: "",
        jumlah_tanggungan: "",
        telepon_ortu: "",
        whatsapp_ortu: "",
        penghasilan_ayah: "",
        penghasilan_ibu: "",
        nama_sekolah: "",
        npsn_sekolah: "",
        nisn_sekolah: "",
        jurusan_sekolah: "",
        tahun_lulus_sekolah: "",
        prestasi1_sekolah: "",
        prestasi2_sekolah: "",
        nama_pt: "",
        alamat_pt: "",
        fakultas_pt: "",
        prodi_pt: "",
        nim_pt: "",
        alamat_rumah: "",
        kepemilikan_rumah: "",
        tahun_perolehan_rumah: "",
        sumber_listrik_rumah: "",
        luas_bangunan_rumah: "",
        facebook: "",
        instagram: "",
        twitter: "",
        foto_kip: "",
        foto_kartu_keluarga: "",
        foto_ktp_ayah_ibu: "",
        foto_ktp_siswa: "",
        foto_sktl: "",
        foto_rekening_listrik: "",
        foto_rekening_air: "",
        foto_keluarga: "",
        foto_ptn: "",
        foto_rumah_depan: "",
        foto_rumah_dalam: "",
        foto_rumah_dapur: "",
        foto_bersama: "",
        foto_pas: "",
        nama_rekomendasi: "",
        whatsapp_rekomendasi: "",
        tanda_tangan_rekomendasi: "",
        tanda_tangan: "",
        whatsapp: "",
        email: "",
        progress: "",
        status: "",
        created_at: "",
        updated_at: ""
      },

      newRecord: { nama_lengkap: "", email: "", whatsapp: "", no_ktp: "" },
      currentRecord: { id: null, nama_lengkap: "", email: "", warna: "" },
      page: "ra_rekomendasi",
      api: "",
      token: "",
      config:
        {}

    };
  },
  components: { NavbarDefault },

  methods: {
    prosesData(id) {
      ShowLoading();
      let formData = new FormData();
      formData.append("url", "ra_rekomendasi_details");
      formData.append("id", id);
      if (process.env.NODE_ENV === "development") {
        this.api = base_url_header + "ra_rekomendasi_details";
      } else {
        this.api = base_url_header;
      }
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            this.records = response.data.data;

            CloseLoading();
            localStorage.setItem(
              "data_siswa",
              JSON.stringify(response.data.data)
            );
            localStorage.setItem("id", id);
            goPage("formulir_kip_kuliah");
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    hideModal() {
      this.$refs.modalCloseUpdate.click();
    },

    index() {
      ShowLoading();
      let formData = new FormData();
      formData.append("url", this.page + "_index");
      if (process.env.NODE_ENV === "development") {
        this.api = base_url_header + this.page + "_index";
      } else {
        this.api = base_url_header;
      }
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            this.records = response.data.data;

            this.records = this.records.filter(record => record.progress === 'PENDING');
            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    insert() {
      ShowLoading();
      const formData = new FormData();

      Object.entries(this.newRecord).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append("url", this.page + "_insert");
      if (process.env.NODE_ENV === "development") {
        this.api = base_url_header + this.page + "_insert";
      } else {
        this.api = base_url_header;
      }
      // Add a new record to the API
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            // Add the new record to the records list
            this.records.push(response.data.data);

            // Clear the form inputs
            this.newRecord = {
              nama_lengkap: "",
              email: "",
              whatsapp: "",
              no_ktp: "",
            };
            CloseLoading();
            AlertBottom(response.data.message);
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    delete_data(id) {
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);
      formData.append("url", this.page + "_delete");
      if (process.env.NODE_ENV === "development") {
        this.api = base_url_header + this.page + "_delete";
      } else {
        this.api = base_url_header;
      }
      // Delete a record from the API
      axios
        .post(this.api, formData, this.config)

        .then((response) => {
          if (response.data.status == true) {
            // Remove the record from the records list
            const index = this.records.findIndex((record) => record.id === id);
            this.records.splice(index, 1);
            CloseLoading();
            AlertBottom(response.data.message);
          } else {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    logout() {
      ShowLoading();
      let formData = new FormData();
      formData.append("url", "logout");
      if (process.env.NODE_ENV === "development") {
        this.api = base_url_header + "logout";
      } else {
        this.api = base_url_header;
      }
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            this.records = response.data.data;
            Cookies.set("token", '');
            goPage('login');
            CloseLoading();
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    generatePDF(id) {
      ShowLoading();
      localStorage.setItem("id_mahasiswa", id);
      CloseLoading();
      goPageNewTab('view_data_mahasiswa');

    },

  },

  mounted() {
    this.token = Cookies.get("token");
    this.config = {
      headers: { Authorization: `Bearer ` + this.token },
      timeout: 5000,
    }
    this.index();
  },
};
</script>

