<template>
  <NavbarDefault />
  <section id="form_pendaftaran" class="poppins" @contextmenu.prevent="disableRightClick">
    <div class="mt-5 pt-5"></div>
    <h4 class="text-center mb-3 fw-bold">
      FORMULIR KIP KULIAH {{ currentYear }} (TIM VERIFIKASI)
    </h4>
    <div class="container">
      <form @submit.prevent="insert">
        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          DATA SISWA
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="nama_lengkap" class="form-label">
                Nama Lengkap<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <input type="text" name="nama_lengkap" class="form-control" id="nis" v-model="currentRecord.nama_lengkap"
                disabled />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="email" class="form-label">
                Email<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <input type="text" name="email" class="form-control" id="email" v-model="currentRecord.email" disabled />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="whatsapp" class="form-label">
                Whatsapp<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <input type="text" name="whatsapp" class="form-control" id="email" v-model="currentRecord.whatsapp"
                disabled />
            </div>
          </div>
        </div>

        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          KELENGKAPAN DATA
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="foto_rumah_depan" class="form-label">
                Foto Rumah(Tampak Depan)<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <input type="file" name="foto_rumah_depan" class="form-control" id="Foto_rumah_depan"
                ref="foto_rumah_depan" required />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="foto_rumah_dalam" class="form-label">
                Foto Rumah(Dalam Rumah)<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <input type="file" name="foto_rumah_dalam" class="form-control" id="foto_rumah_dalam"
                ref="foto_rumah_dalam" required />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="foto_rumah_dapur" class="form-label">
                Foto Rumah(Dapur Rumah)<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <input type="file" name="foto_rumah_dapur" class="form-control" id="foto_rumah_dapur"
                ref="foto_rumah_dapur" required />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="foto_bersama" class="form-label">
                Upload Foto bersama <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <input type="file" name="foto_bersama" class="form-control" id="foto_bersama" ref="foto_bersama"
                required />
            </div>
          </div>
        </div>

        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          TIM YANG MENVERIFIKASI
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="nama_verifikator" class="form-label">
                Nama <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nama_verifikator" class="form-control" id="nis"
                v-model="currentRecord.nama_verifikator" required />
            </div>

            <div class="col-12 col-md-3">
              <label for="no_wa" class="form-label">
                No Whatsapp <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="no_wa" class="form-control" id="nis" v-model="currentRecord.telepon_verifikator"
                required />
            </div>

            <div class="col-12 mb-3">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
                <label class="form-check-label" for="flexCheckDefault">
                  Dengan ini saya menyatakan dengan penuh kesadaran bahwa data
                  yang saya isi dalam formulir ini adalah benar. Saya bersedia
                  diberikan sanksi apabila melanggar ketentuan dan memberikan
                  informasi yang tidak benar.
                </label>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-12 px-2 mb-5 position-relative" style="height: 100px">
              <vue-perfect-signature v-model="signatureData" :stroke-options="{
                size: 5,
                height: 1,
                width: 1,
                background: '#fff',
                penColor: '#000',
              }" ref="signaturePad" class="border rounded w-100" />
              <div class="w-100 text-center">Tanda Tangan Tim Verifikasi</div>
              <button type="button" class="position-absolute btn btn-danger btn-sm top-0"
                @click="clearSignature(signaturePad)">
                x
              </button>
            </div>
            <div class="col-9"></div>
            <div class="col-12 bg-warning border rounded px-2 py-2 mb-2">
              Apabila kami temukan penyalahgunaan formulir atau pengutipan dana
              dalam segala proses Program KIP Kuliah akan kami proses sesuai
              dengan ketentuan hukum yang berlaku
            </div>

            <div class="col-12 bg-warning px-2 py-2 rounded">
              Perhatian : Segala Pengurusan, Pengusulan, dan pencairan dana
              program KIP Kuliah Aspirasi DR. SOFYAN TAN ini tidak dipungut
              biaya Apabila ada yang menyalahgunakan formulir dan atau
              mengutip dana terkait Pengusulan maupun pencairan dana KIP KULIAH
              agar segera lapor ke Rumah Aspirasi DR. Sofyan Tan Komplek CBD
              Polonia Blok G No.87 Polonia
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-end mt-3">
              <button type="submit" class="btn btn-danger" @click="prosesData()">
                Proses
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>

  <!-- <div class="text-end">
    <span class="me-2">Selanjutnya</span>
    <button type="submit" class="btn btn-success rounded-circle py-2">
      <i class="bx bx-arrow-back bx-rotate-180"></i>
    </button>
  </div> -->
</template>

<script>
// @ is an alias to /src
import Cookies from "js-cookie";
import {
  goPage,
  base_url_header,
  AlertPopup,
  ErrorConnectionTimeOut,
  ShowLoading,

} from "@/assets/javascript/function";
import NavbarDefault from "@/components/NavbarDefault.vue";
import VuePerfectSignature from "v-perfect-signature";
import axios from "axios";



export default {
  data() {
    return {
      token: "",
      config: {},
      page: "ra_verifikasi_proses",
      api: "",

      name: "testing",
      selectedOption: "Islam",
      selectedOption2: "Pria",
      pekerjaan_ayah: "PNS",
      pekerjaan_ibu: "PNS",
      statusOrangTua: "Kandung",

      kepemilikanList: [
        {
          nama: "Milik Sendiri",
        },
        {
          nama: "Kontrak/Sewa",
        },
        {
          nama: "Bebas Sewa(menumpang)",
        },
        {
          nama: "Dinas",
        },
        {
          nama: "Lainnya",
        },
      ],
      statusOrangTuaList: [
        {
          nama: "Kandung",
        },
        {
          nama: "Tiri",
        },
        {
          nama: "Angkat",
        },
        {
          nama: "Lainnya",
        },
      ],
      pekerjaanList: [
        {
          nama: "PNS",
        },
        {
          nama: "PEG SWASTA",
        },
        {
          nama: "WIRAUSAHA",
        },
        {
          nama: "TNI/POLRI",
        },
        {
          nama: "PETANI",
        },
        {
          nama: "NELAYAN",
        },
        {
          nama: "LAINNYA",
        },
      ], // an empty array to store options
      prodi: "", // a string to store the selected option

      currentRecord: {
        id: "",

        nama_lengkap: "",
        whatsapp: "",
        email: "",

        //tim verifikator
        nama_verifikator: "",
        telepon_verifikator: "",
        tanda_tangan_rekomendasi: "",


        foto_rumah_depan: "",
        foto_rumah_dalam: "",
        foto_rumah_dapur: "",
        foto_bersama: "",


      },

      currentYear: new Date().getFullYear(),


      signatureData: null,
    };
  },
  name: "HomeView",
  components: { NavbarDefault, VuePerfectSignature },
  methods: {
    disableRightClick(event) {
      event.preventDefault();
    },
    indexData() {
      if (localStorage.getItem("data_siswa") !== null) {
        let data = JSON.parse(localStorage.getItem("data_siswa"));
        // this.currentRecord = Object.assign({}, data);
        this.currentRecord.nama_lengkap = data[0].nama_lengkap;
        this.currentRecord.email = data[0].email;
        this.currentRecord.whatsapp = data[0].whatsapp;

        this.currentRecord.nama_verifikator = data[0].nama_verifikator;
        this.currentRecord.telepon_verifikator = data[0].telepon_verifikator;
      }
    },
    prosesData() {
      ShowLoading();
      const formData = new FormData();
      this.currentRecord.id = localStorage.getItem("id");
      this.currentRecord.foto_rumah_depan =
        this.$refs.foto_rumah_depan.files[0];
      this.currentRecord.foto_rumah_dalam =
        this.$refs.foto_rumah_dalam.files[0];
      this.currentRecord.foto_rumah_dapur =
        this.$refs.foto_rumah_dapur.files[0];
      this.currentRecord.foto_bersama = this.$refs.foto_bersama.files[0];

      // this.currentRecord.tanda_tangan_rekomendasi = this.saveSignature();



      Object.entries(this.currentRecord).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("url", "ra_verifikasi_proses");

      if (process.env.NODE_ENV === "development") {
        this.api = base_url_header + "ra_verifikasi_proses";
      } else {
        this.api = base_url_header;
      }

      // Add a new record to the API
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertPopup("success", response.data.message, "", 1500, false);
            goPage("data_verifikasi");
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    updateSelectedOption(option) {
      this.selectedOption = option;
    },
    updateSelectedOption2(option) {
      this.selectedOption2 = option;
    },
    onOptionSelected() {
      // get the selected option value
      Cookies.set("nama_jurusan", this.prodi);
    },

    saveSignature() {
      const dataURL = this.$refs.signaturePad.toDataURL();
      return dataURL;
    },
    clearSignature2() {
      this.$refs.signaturePad2.clear();
    },
    clearSignature() {
      this.$refs.signaturePad.clear();
    },
  },

  mounted() {
    this.token = Cookies.get("token");
    this.config = {
      headers: { Authorization: `Bearer ` + this.token },
      timeout: 5000,
    }
    this.indexData();
  },
};
</script>

<style></style>
