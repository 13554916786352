<template>
  <div class="container">
    <div class="px-3">
      <div class="fixed-top w-100  py-2 d-flex" style="width: fit-content; background-color: white">
        <div class="container d-flex justify-content-between align-items-center">
          <!-- dicek nanti ki ini yang 3 di comment -->
          <img v-if="isMobile == false" src="@/assets/img/login_logo.png" alt="" class="height-logo-navbar" />
          <img v-if="isMobile == true" src="@/assets/img/login_logo.png" alt="" class="height-logo-navbar"
            style="max-height: 40px; width: auto;" />

          <img src="@/assets/img/login_judul.png" alt="" class="height-logo-navbar" />
          <div class="poppins " v-if="isMobile == false">
            <a v-if="isMobile" target="_blank" href="mailto:rumahaspirasi@drsofyantan.com">
              <div class="d-flex align-items-center">
                <i class="ri-mail-line fs-6 pe-2 text-danger"></i> rumahaspirasi@drsofyantan.com
              </div>
            </a>
            <a v-else target="_blank" class="d-flex align-items-center"
              href="https://mail.google.com/mail/u/0/?fs=1&to=rumahaspirasi@drsofyantan.com&tf=cm">
              <i class="ri-mail-line fs-6 pe-2 text-danger"></i> rumahaspirasi@drsofyantan.com
            </a>
            <div class="d-flex">
              <a target="_blank" class="d-flex align-items-center"
                href="https://api.whatsapp.com/send?phone=6282162887077&text=Hallo%2C%20Rumah%20Aspirasi%20saya%20butuh%20bantuan">
                <i class="ri-whatsapp-line fs-6 pe-2 text-success"></i> Call Center (0821 6288 7077)
              </a>
            </div>
          </div>
          <div v-if="isMobile">
            <button class="btn" @click="showBantuan();">
              <i class="ri-question-line fs-1 text-success"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <button ref="click_modal_bantuan" data-bs-toggle="modal" data-bs-target="#bantuan" hidden>
      click
    </button>
    <div class="modal fade" id="bantuan" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered ">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="label_data_karyawan">
              Bantuan <span class="text-capitalize"></span>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body modal-body2">
            <a target="_blank" href="mailto:rumahaspirasi@drsofyantan.com" class="d-flex align-items-center ps-2">
              <i class="ri-mail-line fs-6 pe-2 text-danger"></i> rumahaspirasi@drsofyantan.com
            </a>
            <a target="_blank" class="d-flex align-items-center ps-2"
              href="https://api.whatsapp.com/send?phone=6282162887077&text=Hallo%2C%20Universitas%20Satya%20terra%20Bhinneka%20saya%20butuh%20bantuan">
              <i class="ri-whatsapp-line fs-6 pe-2 text-success"></i> Admin (0821 6288 7077)
            </a>
            <!-- <a target="_blank" class="d-flex align-items-center ps-2"
              href="https://api.whatsapp.com/send?phone=628116150822&text=Hallo%2C%20Universitas%20Satya%20terra%20Bhinneka%20saya%20butuh%20bantuan">
              <i class="ri-whatsapp-line fs-6 pe-2 text-success "></i> +(62) 88116150822
            </a>
            <a target="_blank" class="d-flex align-items-center ps-2"
              href="https://api.whatsapp.com/send?phone=628126425788&text=Hallo%2C%20Universitas%20Satya%20terra%20Bhinneka%20saya%20butuh%20bantuan">
              <i class="ri-whatsapp-line fs-6 pe-2 text-success"></i> +(62) 8126425788
            </a> -->

          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Batal
            </button>
            <button type="submit" class="btn btn-danger">Simpan</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      isMobile: false,
    }
  },
  methods: {
    showBantuan() {
      this.$refs.click_modal_bantuan.click();
    }
  },
  mounted() {
    const userAgent = navigator.userAgent.toLowerCase();
    this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 1024px) {
  .height-logo-navbar {
    max-height: 60px;
  }
}

@media screen and (min-width: 1024px) {
  .height-logo-navbar {
    max-height: 70px;
  }
}
</style>
