import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/auth/HomeView.vue";
import LoginView from "../views/auth/LoginView.vue";
import NotFoundView from "../views/NotFoundView.vue";
import FormulirKipKuliahView from "../views/pendaftaran/FormulirKipKuliahView.vue";
import ReportRegisterView from "../views/pendaftaran/ReportRegisterView.vue";
import DataVerifikasiView from "../views/kelola_data/DataVerifikasiView.vue";
import FormulirKipKuliahSiswaView from "../views/pendaftaran/FormulirKipKuliahSiswaView.vue";
import TesTertulisView from "../views/pendaftaran/TestTertulisView.vue";
import DataMahasiswaView from "../views/kelola_data/testPdfView.vue";
import FormulirUploadLinkBuktiPendaftaranView from "../views/pendaftaran/FormulirUploadLinkBuktiPendaftaranView.vue";
import VerifikasiEmailView from "../views/pendaftaran/VerifikasiEmailView.vue";
import AccountView from "../views/pendaftaran/AccountView.vue";
import Cookies from "js-cookie";

const routes = [
  //auth
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/formulir_kip_kuliah",
    name: "FormulirKipKuliah",
    component: FormulirKipKuliahView,
    meta: { requiresAuth: true },
  },
  {
    path: "/data_verifikasi",
    name: "DataVerifikasi",
    component: DataVerifikasiView,
    meta: { requiresAuth: true },
  },
  {
    path: "/report_register",
    name: "ReportRegister",
    component: ReportRegisterView,
  },
  //siswa
  {
    path: "/formulir_kip_kuliah_siswa",
    name: "FormulirKipKuliahSiswa",
    component: FormulirKipKuliahSiswaView,
  },

  {
    path: "/test_tertulis",
    name: "Test_tertulis",
    component: TesTertulisView,
  },
  {
    path: "/view_data_mahasiswa",
    name: "view_data_mahasiswa",
    component: DataMahasiswaView,
  },
  {
    path: "/formulir_upload_bukti_pendaftaran",
    name: "formulir_upload_bukti_pendaftaran",
    component: FormulirUploadLinkBuktiPendaftaranView,
  },

  {
    path: "/account",
    name: "account",
    component: AccountView,
  },

  {
    path: "/verifikasi_email",
    name: "verifikasi_email",
    component: VerifikasiEmailView,
  },

  //default
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  const token = Cookies.get("token");
  if (to.matched.some((route) => route.meta.requiresAuth) && !token) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
