<template>
  <NavbarDefault />
  <div id="form_pendaftaran" class="poppins">
    <div class="mt-5 pt-5"></div>
    <h4 class="text-center mb-3 fw-bold">TEST TERTULIS</h4>
    <div class="container">
      <form @submit.prevent="insert">
        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          DATA DIRI
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="nama" class="form-label">
                Nama Lengkap <span class="text-danger"> </span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nama" class="form-control" id="nis" v-model="currentRecord.nama_lengkap"
                disabled />
            </div>
            <div class="col-12 col-md-3">
              <label for="nama" class="form-label">
                NISN <span class="text-danger"> </span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nama" class="form-control" id="nis" v-model="currentRecord.nisn_sekolah"
                disabled />
            </div>
            <div class="col-12 col-md-3">
              <label for="nama" class="form-label">
                NIK <span class="text-danger"> </span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nama" class="form-control" id="nis" v-model="currentRecord.no_ktp" disabled />
            </div>
            <div class="col-12 col-md-3">
              <label for="no_kk" class="form-label">
                NO Kartu Keluarga <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="no_kk" class="form-control" id="no_kk" v-model="currentRecord.no_kk"
                minlength="16" maxlength="16" required />
            </div>
            <div class="col-12 col-md-3">
              <label for="nik" class="form-label">
                NIK Ayah <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nik_ayah" class="form-control" id="nik_ayah" v-model="currentRecord.nik_ayah"
                minlength="16" maxlength="16" required />
            </div>
            <div class="col-12 col-md-3">
              <label for="nik" class="form-label">
                NIK Ibu <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nik_ibu" class="form-control" id="nik_ibu" v-model="currentRecord.nik_ibu"
                minlength="16" maxlength="16" required />
            </div>
            <div class="col-12 col-md-3">
              <label for="nama" class="form-label">
                Asal SMA/SMK/MA <span class="text-danger"> </span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nama" class="form-control" id="nis" v-model="currentRecord.nama_sekolah"
                disabled />
            </div>
            <div class="col-12 col-md-3">
              <label for="nama" class="form-label">
                Universitas <span class="text-danger"> </span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nama" class="form-control" id="nis" v-model="currentRecord.nama_pt" disabled />
            </div>
            <div class="col-12 col-md-3">
              <label for="nama" class="form-label">
                Jurusan<span class="text-danger"> </span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nama" class="form-control" id="nis" v-model="currentRecord.prodi_pt" disabled />
            </div>
            <div class="col-12 col-md-3">
              <label for="nama" class="form-label">
                Tim Rekomendasi<span class="text-danger"> </span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nama" class="form-control" id="nis" v-model="currentRecord.nama_rekomendasi"
                disabled />
            </div>
          </div>
        </div>

        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          Soal
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 mb-3">
              Jawab Pertanyaan berikut dengan singkat dan jelas
            </div>
            <div class="col-12 ">
              <label for="nama" class="form-label">
                1. Dari mana anda tahu informasi beasiswa ini dan siapa yang merekomendasikan untuk mengikuti program
                ini?

              </label>
            </div>
            <div class="col-12  mb-3">
              <textarea name="" id="" cols="30" rows="4" class="form-control"
                v-model="currentRecord.jawaban1"> </textarea>

            </div>
            <div class="col-12 ">
              <label for="nama" class="form-label">
                2. Apa yang melatar belakangi anda untuk ikut serta pada program beasiswa KIP Kuliah ini. (jelaskan)

              </label>
            </div>
            <div class="col-12  mb-3">
              <textarea name="" id="" cols="30" rows="4" class="form-control"
                v-model="currentRecord.jawaban2"> </textarea>
            </div>
            <div class="col-12 ">
              <label for="nama" class="form-label">
                3. Jelaskan secara singkat bagaimana pandangan anda tentang kebhinekaan bangsa!

              </label>
            </div>
            <div class="col-12  mb-3">
              <textarea name="" id="" cols="30" rows="4" class="form-control"
                v-model="currentRecord.jawaban3"> </textarea>
            </div>

            <div class="col-12 ">
              <label for="nama" class="form-label">
                4. Apa pandangan anda tentang diskriminasi dan radikalisme !

              </label>
            </div>
            <div class="col-12  mb-3">
              <textarea name="" id="" cols="30" rows="4" class="form-control"
                v-model="currentRecord.jawaban4"> </textarea>
            </div>

            <div class="col-12 ">
              <label for="nama" class="form-label">
                5. Apakah anda mengenal dr. Sofyan Tan ?. jelaskan apa yang dapat anda kemukakan tentang sosok Bapak
                tersebut!

              </label>
            </div>
            <div class="col-12  mb-3">
              <textarea name="" id="" cols="30" rows="4" class="form-control"
                v-model="currentRecord.jawaban5"> </textarea>
            </div>

            <div class="col-12 ">
              <label for="nama" class="form-label">
                6. dr Sofyan Tan dikenal seorang tokoh selalu memperjuangkan pentingnya menjaga keberagaman bangsa,
                peduli
                sosial dengan membantu orang yang kurang mampu melalui Pendidikan dan program lainnya. Jika anda
                berhasil
                menerima beasiswa ini. Kontribusi apa yang dapat anda berikan untuk mendukung program tersebut

              </label>
            </div>
            <div class="col-12  mb-3">
              <textarea name="" id="" cols="30" rows="4" class="form-control"
                v-model="currentRecord.jawaban6"> </textarea>
            </div>

            <div class="col-12 ">
              <label for="nama" class="form-label">
                7. Tuliskan miminal 3 faktor atau alasan yang anda gunakan dalam memilih seorang pemimpin!

              </label>
            </div>
            <div class="col-12  mb-3">
              <textarea name="" id="" cols="30" rows="4" class="form-control"
                v-model="currentRecord.jawaban7"> </textarea>
            </div>

            <div class="col-12 ">
              <label for="nama" class="form-label">
                8. Jika ada pertemuan yang dilakukan oleh Tim Rumah Aspirasi, apakah anda bersedia untuk mengikuti
                kegiatan tersebut ? jelaskan alasanya !

              </label>
            </div>
            <div class="col-12  mb-3">
              <textarea name="" id="" cols="30" rows="4" class="form-control"
                v-model="currentRecord.jawaban8"> </textarea>
            </div>

            <div class="col-12 ">
              <label for="nama" class="form-label">
                9. Jika anda diterima sebagai penerima KIP Kuliah melalui jalur aspirasi, kontribusi apa yang dapat anda
                berikan untuk mendukung program yang ada di rumah aspirasi ?
              </label>
            </div>
            <div class="col-12  mb-3">
              <textarea name="" id="" cols="30" rows="4" class="form-control"
                v-model="currentRecord.jawaban9"> </textarea>
            </div>

            <div class="col-12 ">
              <label for="nama" class="form-label">
                10. Salah satu fungsi partai politik adalah sarana sosialisasi politik. Bagaimana tanggapan anda
                terhadap
                pernyataan tersebut !
              </label>
            </div>
            <div class="col-12  mb-3">
              <textarea name="" id="" cols="30" rows="4" class="form-control"
                v-model="currentRecord.jawaban10"> </textarea>
            </div>
            <div class="col-lg-3 col-md-4 col-12 px-2 mb-5 position-relative" style="height: 100px">
              <vue-perfect-signature v-model="signatureData" :stroke-options="{
                size: 5,
                height: 1,
                width: 1,
                background: '#fff',
                penColor: '#000',
              }" ref="signaturePad" class="border rounded w-100" />
              <div class="w-100 text-center">Tanda Tangan Siswa</div>
              <button type="button" class="position-absolute btn btn-danger btn-sm top-0"
                @click="clearSignature(signaturePad)">
                x
              </button>
            </div>
            <div class="text-end">
              <button type="submit" class="btn btn-danger">
                Simpan
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>


  <button class="btn" ref="modalShowButton" data-bs-toggle="modal" data-bs-target="#tambah_data" hidden></button>
  <div class="modal fade" id="tambah_data" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="label_data_karyawan">Kode Akses</h5>
          <!-- <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button> -->
        </div>
        <form @submit.prevent="verifikasi()">
          <div class="modal-body">
            <div class="mb-3">
              <label for="kode" class="form-label">
                Masukan kode yang dikirimkan via whatsapp atau email
              </label>
              <input type="text" class="form-control" v-model="kode_unik" placeholder="Kode" />
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Batal
            </button> -->
            <button type="button" ref="close_modal" data-bs-dismiss="modal" aria-label="Close" hidden>

            </button>
            <button type="submit" class="btn btn-primary">
              Verifikasi
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Cookies from "js-cookie";
import { goPage, base_url, AlertPopup, ShowLoading, CloseLoading, ErrorConnectionTimeOut, AlertPopupConfirm } from "@/assets/javascript/function";
import NavbarDefault from "@/components/NavbarDefault.vue";
import VuePerfectSignature from "v-perfect-signature";
import axios from "axios";

export default {
  data() {
    return {
      page: "ra_calon",
      api: "",
      kode_unik: "",
      name: "testing",
      selectedOption: "Islam",
      selectedOption2: "Pria",
      pekerjaan_ayah: "PNS",
      pekerjaan_ibu: "PNS",
      statusOrangTua: "Kandung",


      kepemilikanList: [
        {
          nama: "Milik Sendiri",
        },
        {
          nama: "Kontrak/Sewa",
        },
        {
          nama: "Bebas Sewa(menumpang)",
        },
        {
          nama: "Dinas",
        },
        {
          nama: "Lainnya",
        },
      ],
      statusOrangTuaList: [
        {
          nama: "Kandung",
        },
        {
          nama: "Tiri",
        },
        {
          nama: "Angkat",
        },
        {
          nama: "Lainnya",
        },
      ],
      pekerjaanList: [
        {
          nama: "PNS",
        },
        {
          nama: "PEG SWASTA",
        },
        {
          nama: "WIRAUSAHA",
        },
        {
          nama: "TNI/POLRI",
        },
        {
          nama: "PETANI",
        },
        {
          nama: "NELAYAN",
        },
        {
          nama: "LAINNYA",
        },
      ], // an empty array to store options
      prodi: "", // a string to store the selected option

      currentRecord: {
        nama_lengkap: "",
        jenis_kelamin: "",
        agama: "",
        no_ktp: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        alamat_siswa: "",
        kecamatan: "",
        kabupaten: "",
        kodepos: "",
        telepon_siswa: "",
        whatsapp: "",

        //data keluarga
        nama_ayah: "",
        pekerjaan_ayah: "",
        nama_ibu: "",
        pekerjaan_ibu: "",
        jumlah_tanggungan: "",
        telepon_ortu: "",
        whatsapp_ortu: "",
        status_ortu: "",
        pendidikan_orang_tua: "",
        penghasilan_ayah: "",
        penghasilan_ibu: "",

        //pendidikan
        nama_sekolah: "",
        npsn_sekolah: "",
        nisn_sekolah: "",
        jurusan_sekolah: "",
        tahun_lulus_sekolah: "",
        prestasi1_sekolah: "",
        prestasi2_sekolah: "",

        //pilihan
        nama_pt: "",
        alamat_pt: "",
        fakultas_pt: "",
        prodi_pt: "",
        nim_pt: "",

        //rumah tinggal keluarga
        alamat_rumah: "",
        kepemilikan_rumah: "Bebas Sewa(menumpang)",
        tahun_perolehan_rumah: "",
        sumber_listrik_rumah: "",
        luas_bangunan_rumah: "",

        //aku media sosial
        facebook: "",
        instagram: "",
        twitter: "",

        //tim rekomendasi
        nama_tim_rekomendasi: "",
        no_handphone_tim_rekomendasi: "",
        no_whatsapp_tim_rekomendasi: "",
        foto_bersama: "",
        tanda_tangan: "",
        tanda_tangan_tim_rekomendasi: "",

        foto_kip: "",
        foto_kartu_keluarga: "",
        foto_ktp_ayah_ibu: "",
        foto_ktp_siswa: "",
        foto_sktl: "",
        foto_rekening_listrik: "",
        foto_rekening_air: "",
        foto_keluarga: "",
        foto_pas: "",
        foto_ptn: "",

        //bekas
        nama_ijazah: "",
        warga_negara: "",
        nama_ibu_kandung: "",

        id: "",
        jawaban1: "",
        jawaban2: "",
        jawaban3: "",
        jawaban4: "",
        jawaban5: "",
        jawaban6: "",
        jawaban7: "",
        jawaban8: "",
        jawaban9: "",
        jawaban10: "",


      },
      signatureData: null,
    };
  },
  name: "HomeView",
  components: { NavbarDefault, VuePerfectSignature },
  methods: {
    ShowModal() {
      this.$refs.modalShowButton.click();

    },
    HideModal() {
      this.$refs.close_modal.click();

    },
    saveData() {
      this.currentRecord.agama = this.selectedOption;
      this.currentRecord.jenis_kelamin = this.selectedOption2;
      this.currentRecord.prodi = this.prodi;
      localStorage.setItem("data_diri", JSON.stringify(this.currentRecord));
      goPage("data_akun");
    },
    indexData() {
      //note nanti diganti
      this.currentRecord.nama_ijazah = this.currentRecord.nama_lengkap;
      this.currentRecord.warga_negara = "WNI";
      if (
        Cookies.get("nama_jurusan") != "" ||
        Cookies.get("nama_jurusan") != null
      ) {
        this.prodi = Cookies.get("nama_jurusan");
      }

      if (localStorage.getItem("data_diri") !== null) {
        let data = JSON.parse(localStorage.getItem("data_diri"));
        this.currentRecord = Object.assign({}, data);
        this.selectedOption = this.currentRecord.agama;
        this.selectedOption2 = this.currentRecord.jenis_kelamin;
        this.currentRecord.prodi = this.prodi;
        this.kode_unik = this.currentRecord.kode_unik;

        CloseLoading();
      }
    },
    insert() {
      ShowLoading();
      const formData = new FormData();

      Object.entries(this.currentRecord).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.append('tanda_tangan', this.saveSignature());
      formData.append("url", this.page + "_test_insert");
      formData.append("id", this.kode_unik);
      // console.log(formData.get('id'));

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      if (process.env.NODE_ENV === "development") {

        this.api = base_url + this.page + "_test_insert";
      } else {
        this.api = base_url + this.page + "_test_insert";
      }



      // Add a new record to the API
      axios
        .post(this.api, formData, { timeout: 30000 })
        .then((response) => {
          if (response.data.status == true) {
            AlertPopup("success", response.data.message, "", 1500, false);
            // console.log(response.data);
            CloseLoading();
            goPage("report_register");
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          AlertPopup("error", "", "terjadi kesalahan silahkan coba lagi", 1500, false);
          ErrorConnectionTimeOut(error)
        });
    },
    verifikasi(id) {
      ShowLoading();
      const formData = new FormData();

      formData.append("url", this.page + "_test");
      formData.append("id", id);
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.page + "_test";
      } else {
        this.api = base_url + this.page + "_test";
      }


      // Add a new record to the API
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {

            this.currentRecord = response.data.data;
            this.HideModal();
            AlertPopup("success", "", "Silahkan Isi Data", 1500, false);
          }
          if (response.data.status == false) {
            AlertPopupConfirm("error", "", response.data.message);
            goPage('/');
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);

        });
    },
    updateSelectedOption(option) {
      this.selectedOption = option;
    },
    updateSelectedOption2(option) {
      this.selectedOption2 = option;
    },
    onOptionSelected() {
      // get the selected option value
      Cookies.set("nama_jurusan", this.prodi);
    },

    saveSignature() {
      const dataURL = this.$refs.signaturePad.toDataURL();
      return dataURL;
    },
    clearSignature2() {
      this.$refs.signaturePad2.clear();


    },
    clearSignature() {
      this.$refs.signaturePad.clear();


    },
  },

  mounted() {
    // this.indexData();
    const str = this.$route.fullPath;
    const slug = str.split('=').pop();
    this.verifikasi(slug);
    this.kode_unik = slug;
    // this.ShowModal();
  },
};
</script>

<style></style>
