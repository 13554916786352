import router from "@/router";

import Swal from "sweetalert2";
import iziToast from "izitoast";
let base = "";
let base2 = "";

if (process.env.NODE_ENV === "development") {
  base = "https://s7-api.arthaskyline.com/";
  base2 = "https://s7-api.arthaskyline.com/";
} else {
  base = "https://s7-api.arthaskyline.com/";
  base2 = "https://s7-api.arthaskyline.com/";
}

export let base_url_header = base;
export let base_url = base2;

export const goPage = (text) => {
  // Do something here
  router.push("/" + text);
};
export const goPageNewTab = (text) => {
  // Do something here
  window.open(process.env.BASE_URL + text, "_blank");
};

export const AlertPopup = (
  icon,
  message,
  submessage,
  timer,
  showConfirmButton
) => {
  Swal.fire({
    icon: icon,
    title: message,
    text: submessage,
    timer: timer,
    showConfirmButton: showConfirmButton,
  });
};

export const AlertPopupConfirm = (icon, message, submessage) => {
  Swal.fire({
    icon: icon,
    title: message,
    text: submessage,
    showConfirmButton: true,
  });
};

export const AlertBottom = (text) => {
  iziToast.success({
    timeout: 1000,
    title: text,
  });
};

export const AlertBottomError = (text) => {
  iziToast.error({
    timeout: 5000,
    title: text,
  });
};
export const AlertBottomInfo = (text) => {
  iziToast.info({
    timeout: 0,
    title: text,
  });
};

export const ShowLoading = () => {
  Swal.fire({
    title: "",
    html: `<img src="https://s7-api.arthaskyline.com/loading_page.gif"  style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;">
`, // add html attribute if you want or remove
    allowOutsideClick: false,
    showConfirmButton: false,
    // didOpen: () => {
    //   Swal.showLoading();
    // },
  });
};

export const CloseLoading = () => {
  Swal.close();
};

export const ErrorConnectionTimeOut = (error) => {
  if (
    error.code === "ECONNABORTED" ||
    error.code === "ERR_NETWORK" ||
    error.code === "ECONNRESET" ||
    error.code === "ETIMEDOUT"
  ) {
    CloseLoading();
    AlertBottomError(
      "Jaringan Internet lambat atau tidak stabil, silahkan coba lagi "
    );
  } else if (error.code === "ECONNREFUSED") {
    CloseLoading();
    AlertBottomError("Jaringan koneksi terputus, silahkan coba lagi ");
  } else {
    console.log(error);
    console.log("Error:", error.message);
  }

  // if (error.code == "ECONNABORTED") {
  //   AlertPopup(
  //     "error",
  //     "Jaringan koneksi internet tidak stabil",
  //     "periksa jaringan internet Anda dan silahkan coba kembali",
  //     0,
  //     true
  //   );
  // } else if (error.code == "ERR_NETWORK") {
  //   AlertPopup(
  //     "error",
  //     "Jaringan koneksi internet tidak stabil",
  //     "periksa jaringan internet Anda dan silahkan coba kembali",
  //     0,
  //     true
  //   );
  // } else {
  //   console.log(error);
  // }
};

export const getBase64Image = (img) => {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;

  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);

  var dataURL = canvas.toDataURL("image/png");

  return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
};

//date
export const JustYearNow = () => {
  var today = new Date();
  var year = today.getFullYear();
  return year;
};

export const randomString = () => {
  let length = 10;
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const AlertPopupGoToURL = (
  message,
  submessage,
  showConfirmButton,
  url
) => {
  Swal.fire({
    title: message,
    text: submessage,
    showConfirmButton: showConfirmButton,
    allowEnterKey: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    icon: "success",
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = url;
    }
  });
};

export let base_url_wilayah_indonesia =
  "https://sandbox.arthaskyline.com/api-wilayah-indonesia-master/";
