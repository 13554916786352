<template>
  <router-view />
</template>

<script>
export default {
  data() {
    return {};
  },

  name: "rumah_aspirasi",
  components: {},

  methods: {},
  computed: {},
  mounted() { },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

:root {
  --jempolku: #dc3545;
  --jempolku2: #1a5ccf;
  --jempolku3: #f0f7fd;

  /* --first-color: #BB141C; */
  --first-color: #e30b21;
  --second-color: #1fb2b6;
  --third-color: #3b367c;
  --fourth-color: #ffd60a;
  --fifth-color: #40916c;
  --sixth-color: #cdb4db;
}

.bg-merah {
  background-color: #BB141B;
}


.poppins {
  font-family: "Poppins", sans-serif;
  /* background-color: var(--third-color); */
}

a {
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  color: black;
}

a:hover {
  color: var(--jempolku);
}

::-moz-selection {
  /* Code for Firefox */
  background: var(--jempolku);
}

::selection {
  background: var(--jempolku);
}

.dropdown-item {
  font-size: 12px !important;
}

.form-select {
  font-size: 12px !important;
}

.form-check-input:checked {
  background-color: var(--jempolku) !important;
  border-color: var(--jempolku) !important;
}

.btn {
  font-size: 12px !important;
}

#btn-menu {
  border-radius: 0.25rem !important;
  font-size: 20px !important;
  padding-top: 0.3rem !important;
  padding-right: 0.6rem !important;
  padding-bottom: 0.2rem !important;
  padding-left: 0.6rem !important;
}

.btn-menu {
  font-size: 15px !important;
}

#dropdown-2 {
  border-radius: 0.25rem !important;
  font-size: 12px !important;
}

.dropend-sub-menu {
  margin-top: -2.25rem !important;
  left: 13.1rem;
}

.card {
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.22) !important;
}

::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 0.5rem;
  background-color: rgba(63, 63, 63, 0.269);
  height: 0.6rem;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(63, 63, 63, 0.333);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(63, 63, 63, 0.566);
}

/* navbarr */

ul.dropdown-menu.show {
  border: none !important;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.22) !important;
}

.dropdown-item:hover {
  color: var(--jempolku) !important;
  background-color: white !important;
}

.dropdown-item:active {
  background-color: white !important;
  /* color: white!important; */
}

.dropdown-menu.show {
  margin-left: 7px !important;
}

.pilih-kelas {
  min-width: 75% !important;
}

.fs-7 {
  font-size: 11px !important;
}

.fs-8 {
  font-size: 10px !important;
}

.ps-6 {
  padding-left: 4rem !important;
}

.form-control {
  font-size: 12px !important;
}

.bg-body {
  background-color: var(--third-color) !important;
}

.row>* {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.data-jenis-karyawan {
  width: 10px;
  height: 10px;
  border-radius: 2px;
}

.data-0 {
  background-color: var(--first-color);
}

.data-1 {
  background-color: var(--second-color);
}

.data-2 {
  background-color: var(--third-color);
}

.data-3 {
  background-color: var(--fourth-color);
}

.data-4 {
  background-color: var(--fifth-color);
}

.data-5 {
  background-color: var(--sixth-color);
}

#myChart {
  width: 100%;
  min-height: 50px;
}

.zc-ref {
  display: none;
}

.container {
  font-size: 12px !important;
}

.daterangepicker .ranges li:active {
  background-color: var(--jempolku) !important;
}

.daterangepicker .ranges li.active {
  background-color: var(--jempolku) !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--jempolku) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--jempolku) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--jempolku) !important;
}

button.applyBtn.btn.btn-sm.btn-primary {
  background-color: var(--jempolku) !important;
}

.fg-dial {
  font-size: 200% !important;
  font-weight: bold !important;
  left: 0 !important;
  position: absolute !important;
  text-align: center !important;
  top: 100px !important;
  width: 100% !important;
}

.fg-dial-label {
  font-size: 200% !important;
  font-weight: bold !important;
  left: 0 !important;
  position: absolute !important;
  text-align: center !important;
  top: 160px !important;
  width: 100% !important;
}

.tooltipz {
  position: relative;
  display: inline-block;
}

.tooltipz .tooltiptextz {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -50px;
  left: -15%;
}

.tooltipz:hover .tooltiptextz {
  visibility: visible;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--jempolku) !important;
}

.bootstrap-select>.dropdown-toggle {
  width: 160% !important;
}
</style>
