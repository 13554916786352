<template>
  <div class="d-sm-none d-flex flex-column poppins" style="height: 100vh">
    <div class="h-100 d-flex flex-column justify-content-center poppins">
      <div class="text-center w-100">
        <img src="@/assets/img/home_logo.png" alt="" style="width: 50vw" />
      </div>
      <div class=" text-center">
        <img src="@/assets/img/home_judul.png" alt="" style="width: 85vw" />
      </div>

      <div class="d-flex justify-content-center mx-3">
        <div class="w-100 mt-5">
          <table>
            <thead>
              <tr>
                <th style="width: 15%; vertical-align: top">No Hp</th>
                <th style="width: 6%; vertical-align: top">:</th>
                <th style="width: 70%; vertical-align: top">
                  <input type="tel" name="tel" class="form-control mb-3" v-model="email" />
                </th>
              </tr>
              <tr>
                <th style="width: 15%; vertical-align: top">Password</th>
                <th style="width: 6%; vertical-align: top">:</th>
                <th style="width: 70%; vertical-align: top">
                  <input type="password" name="password" class="form-control mb-3" v-model="password" />
                </th>
              </tr>
              <tr>
                <th style="width: 15%; vertical-align: top"></th>
                <th style="width: 6%; vertical-align: top"></th>
                <th style="width: 70%; vertical-align: top" class="text-end">
                  <button class="btn btn-danger" @click="Login()">LOGIN</button>
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="d-none d-sm-block container-fluid ps-0 poppins">
    <img src="@/assets/img/home_bendera_2.png" alt="" class="position-absolute" style="width: 20vw; z-index: -1" />
    <div class="row align-items-center">
      <div class="col-lg-6 col-sm-4">
        <div class="login-wisuda"></div>
        <!-- <div class="text-center mt-4">
          Lupa Password?
          <a href="konfirmasi_otp" class="text-danger fw-bold">klik disini</a>
        </div> -->
      </div>
      <div class="col-lg-6 col-sm-8">
        <div class="d-flex flex-column w-100 align-items-center">
          <div class="d-flex flex-column align-items-end w-150">
            <img src="@/assets/img/home_logo.png" alt="" style="width: 15vw" class="" />
            <div>
              <img src="@/assets/img/home_judul.png" alt="" style="width: 40vw" class="" />
            </div>
          </div>
          <div class="d-flex justify-content-center mx-3 w-75">
            <div class="w-100 mt-5">
              <table>
                <thead>
                  <tr>
                    <th style="width: 15%; vertical-align: top">No Hp</th>
                    <th style="width: 6%; vertical-align: top">:</th>
                    <th style="width: 70%; vertical-align: top">
                      <input type="tel" name="email" class="form-control mb-3" v-model="email" />
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 15%; vertical-align: top">password</th>
                    <th style="width: 6%; vertical-align: top">:</th>
                    <th style="width: 70%; vertical-align: top">
                      <input type="password" name="password" class="form-control mb-3" v-model="password" />
                    </th>
                  </tr>
                  <tr>
                    <th style="width: 15%; vertical-align: top"></th>
                    <th style="width: 6%; vertical-align: top"></th>
                    <th style="width: 70%; vertical-align: top" class="text-end">
                      <button class="btn btn-danger" @click="Login()">
                        LOGIN
                      </button>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div class="text-center ps-5" style="width: 35vw"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  base_url,
  AlertPopup,
  goPage,
  ShowLoading,
  CloseLoading,
} from "@/assets/javascript/function";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      email: "",
      password: "",
      url: "login",
      api: "",
    };
  },

  methods: {
    Login() {
      ShowLoading();
      let formData = new FormData();
      this.email = "62" + this.email.substring(1);
      formData.append("username", this.email);
      formData.append("password", this.password);
      formData.append("url", this.url);
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.url;
      } else {
        this.api = base_url + this.url;
      }
      // formData.append("url", this.url);
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            if (response.data.tipe != "users") {

              AlertPopup("success", "Selamat Datang", "", 1500, false);
              Cookies.set("token", response.data.token_refresh);
              goPage("data_verifikasi");
            } else {
              AlertPopup("error", "Tidak Terdaftar", "", 1500, false);
              this.email = "0" + this.email.substring(2);
            }
          } else {
            // this.showAlert(response.data.message);
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          console.log(error);
          CloseLoading();
        });
    },
  },
};
</script>

<style>
.login-page {
  min-height: 100vh;
  /* background-color: var(--jempolku); */
}

.login-logo {
  min-height: 100vh;
  max-height: 500px;
  background-image: url("@/assets/img/login.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-wisuda {
  min-height: 100vh;
  max-height: 500px;
  background-image: url("@/assets/img/login.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.icon-whatsapp-logo {
  font-size: 2rem !important;
}

.input-form-login {
  border-radius: 1rem !important;
  height: 60px;
}

#background-login-form {
  border-radius: 0.7rem !important;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.34);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.34);
}

.background-login-form {
  border-radius: 0.7rem !important;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.34);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.34);
}

/* .btn-primary {
  color: #fff;
  background-color: var(--jempolku2) !important;
  border-color: var(--jempolku2) !important;
} */
.row>* {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.bg-jempolku {
  background-color: var(--jempolku);
}

.py-px-login-card {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

@media screen and (max-width: 1024px) {
  .login-logo {
    background-size: cover;
  }

  .login-wisdua {
    background-size: cover;
  }
}
</style>
