// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/login.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/img/home_orang.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-page{min-height:100vh}.login-logo{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%}.login-logo,.login-orang{min-height:100vh;max-height:500px;background-size:cover;background-repeat:no-repeat}.login-orang{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:25% 15%}.icon-whatsapp-logo{font-size:2rem!important}.input-form-login{border-radius:1rem!important;height:60px}#background-login-form,.background-login-form{border-radius:.7rem!important;box-shadow:5px 5px 5px 0 rgba(0,0,0,.34);-webkit-box-shadow:5px 5px 5px 0 rgba(0,0,0,.34);-moz-box-shadow:5px 5px 5px 0 rgba(0,0,0,.34)}.row>*{padding-right:0!important;padding-left:0!important}.bg-jempolku{background-color:var(--jempolku)}.py-px-login-card{padding-top:1rem!important;padding-bottom:1rem!important;padding-right:1rem!important;padding-left:1rem!important}@media screen and (max-width:1024px){.login-logo,.login-orang{background-size:cover}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
